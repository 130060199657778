<script>
import { required, url } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";

const initialState = () => {
    return {
        submitted: false,
        notification: {
            media_id: null,
            media: null,
            promotion_id: null,
            promotion: null,
            advantage_id: null,
            advantage: null,
            raffle_id: null,
            raffle: null,
            action_type: null,
            title: '',
            body: '',
            topic: 'general',
            schedule_date: '',
            external_url: '',
            is_send: false,
        },
        notificationTypes: {
            promotion: "Promoción",
            advantage: "Ventaja",
            raffle: "Sorteo",
            external: "Link externo",
        },
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        AddIndividualMediaButton,
        SelectAutocompleteField
    },
    validations: {
        notification: {
            title: { required },
            body: {},
            promotion: {},
            advantage: {},
            raffle: {},
            external_url: { url },
            schedule_date: { required },
        }
    },
    methods: {
        async shown() {

            if (this.id) {
                const response = await api.get({
                    url: `notifications/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                const notification = response?.data?.data;
                this.notification = notification;
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.notification;
            data.media_id = this.notification?.media?.id;
            data.promotion_id = this.notification?.promotion?.id;
            data.advantage_id = this.notification?.advantage?.id;
            data.raffle_id = this.notification?.raffle?.id;
            data.is_send = false;

            await api.save({
                url: 'notifications',
                id: this.notification?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de notificación'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">
            <div class="col-12" v-if="notification?.is_send && notification?.id">
                <div class="alert alert-warning text-center">
                    <strong>Nota:</strong> Al actualizar los datos de la notificación, se volverá a marcar como no enviada
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-title-desc">
                            Ingresa los datos generales de la notificación
                        </p>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="title">Titulo</label>
                                <input id="title" v-model="notification.title" type="text" class="form-control" placeholder="Ingresa un titulo para la notificación"
                                    :class="{ 'is-invalid': $v.notification.title.$error && submitted }" />
                                <div v-if="!$v.notification.title.required && submitted" class="invalid-feedback">Titulo es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="body">Descripción</label>
                                <textarea
                                    id="body"
                                    v-model="notification.body"
                                    class="form-control"
                                    :maxlength="225"
                                    rows="3"
                                    placeholder="Ingresa una descripción"
                                    :class="{ 'is-invalid': $v.notification.body.$error && submitted }"
                                ></textarea>
                            </div>
                            <div class="col-12">
                                <label>Imagen</label>
                                <AddIndividualMediaButton :onlyImages="true" v-model="notification.media"></AddIndividualMediaButton>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">
                            Configuración
                        </h4>
                        <p class="card-title-desc">
                            Personaliza las caracteristicas de la notificación
                        </p>    
                        <div class="row">
                            <div class="col-12">
                                <label>Acción</label>
                            </div>
                            <div class="col-12 d-flex">
                                <div
                                    v-for="(label, value) in notificationTypes"
                                    :key="value"
                                    class="custom-control custom-radio mb-2 mr-2"
                                >
                                    <input
                                        type="radio"
                                        :id="'notificationType' + value"
                                        name="notificationType"
                                        class="custom-control-input"
                                        :value="value"
                                        v-model="notification.action_type"
                                    />
                                    <label class="custom-control-label" :for="'notificationType' + value">
                                        {{ label }}
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-12">
                                <SelectAutocompleteField
                                    v-show="notification.action_type === 'promotion'"
                                    :url="`/promotions`"
                                    :params="{ status: 'publish' }"
                                    :multiple="false"
                                    :placeholder="'Busca una promoción'"
                                    :label="'title'"
                                    v-model="notification.promotion"
                                    :class="{ 'is-invalid': $v.notification.promotion.$error && submitted }"
                                ></SelectAutocompleteField>
                                <SelectAutocompleteField
                                    v-show="notification.action_type === 'advantage'"
                                    :url="`/advantages`"
                                    :params="{ status: 'publish' }"
                                    :multiple="false"
                                    :placeholder="'Busca una ventaja'"
                                    :label="'name'"
                                    v-model="notification.advantage"
                                    :class="{ 'is-invalid': $v.notification.advantage.$error && submitted }"
                                ></SelectAutocompleteField>
                                <SelectAutocompleteField
                                    v-show="notification.action_type === 'raffle'"
                                    :url="`/raffles`"
                                    :params="{ status: 'publish' }"
                                    :multiple="false"
                                    :placeholder="'Busca un sorteo'"
                                    :label="'title'"
                                    v-model="notification.raffle"
                                    :class="{ 'is-invalid': $v.notification.raffle.$error && submitted }"
                                ></SelectAutocompleteField>
                                <input 
                                    v-show="notification.action_type === 'external'"
                                    id="external_url"
                                    v-model="notification.external_url"
                                    type="text"
                                    class="form-control"
                                    placeholder="Ingresa una url para la notificación"
                                    :class="{ 'is-invalid': $v.notification.external_url.$error && submitted }"
                                />
                            </div>
                            <div class="form-group col-12">
                                <label for="schedule_date">Programa la fecha y hora de envío de la notificación</label>
                                <input
                                    id="schedule_date"
                                    v-model="notification.schedule_date"
                                    type="datetime-local"
                                    class="form-control"
                                    placeholder="Ingresa la fecha y hora de envío de la notificación"
                                    :class="{ 'is-invalid': $v.notification.schedule_date.$error && submitted }"
                                />
                                <div v-if="!$v.notification.schedule_date.required && submitted" class="invalid-feedback">Fecha de envío es requerida</div>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>