<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import NotificationForm from "./forms/notification-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import DateRangeField from '@/components/forms/date-range-field.vue';

/**
 * Notifications Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        CustomTable,
        NotificationForm,
        AddButton,
        DateRangeField
    },
    data() {
        return {
            formModalId: 'notification-form-modal',
            pageHeader: {
                title: "Notificaciones",
                breadcrumbs: [
                    {
                        text: "Notificaciones",
                        href: "/notifications",
                        active: true
                    }
                ],
            },
            table: {
                url: '/notifications',
                params: {
                    'with_trashed': 1,
                    'is_send': 1,
                    'status': 'all'
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "created_at", label: "Fecha de creación", sortable: true },
                    { key: "title", label: "Titulo", sortable: true },
                    { key: "body", label: "Descripción", sortable: true },
                    { key: "user.name", label: "Creado por", sortable: false },
                    { key: "schedule_date", label: "Envío programado para", sortable: false },
                    { key: "is_send", label: "Enviado", sortable: false },
                ],
                formatted_fields: [
                    'is_send',
                ]
            },
            formSelectedItemId: null,
            filters: {
                is_send: null,
                schedule_date_range: {
                    startDate: null,
                    endDate: null
                },
                created_at_range: {
                    startDate: null,
                    endDate: null
                },
                send_date_range: {
                    startDate: null,
                    endDate: null
                },
            }
        };
    },
    methods: {
        showFormModal(item) {
            this.formSelectedItemId = item?.id || null;
            this.$bvModal.show(this.formModalId);
        },        
        onSuccess() {
            this.$refs.notificationTable.refresh();
        },
        formatter(field, item) {
            switch (field) {
                case 'is_send':
                {
                    const title = item?.is_send ? `Enviado: ${item?.send_date}` : 'No enviado';
                    const status = Status.getStatus({ itemStatus: item?.is_send || 0, type: 'yes_not', html: true });
                    return `<span title="${title}">${status}</span>`;
                }                    
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <NotificationForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess"></NotificationForm>
                        <CustomTable
                            :url="table.url"
                            :fields="table.fields"
                            :params="table.params"
                            ref="notificationTable"
                            :formatted-fields="table.formatted_fields"
                            :formatter="formatter"
                            :show-view="false"
                            :show-change-status="true"
                            :show-edit="true"
                            :showSoftDelete="false" 
                            :filters2="filters"
                            @edit="showFormModal"
                        >
                        <template v-slot:filters="{ filters }">
                                <div class="row">
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Fecha de creación</label>
                                        <DateRangeField v-model="filters.created_at_range" />
                                    </div>                                    
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Fecha programada</label>
                                        <DateRangeField v-model="filters.schedule_date_range" />
                                    </div>                                    
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Fecha de envío</label>
                                        <DateRangeField v-model="filters.send_date_range" />
                                    </div>                                    
                                </div>
                            </template>
                            <template v-slot:header-actions>
                                <AddButton @click="showFormModal"
                                    v-has-permission="{ permission: 'notifications.update', action: 'disable' }"></AddButton>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>